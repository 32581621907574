@import "colors";
@import "spacing";
@import "util";

$topPadding: 2 * $std-space;
$sidePadding: 4 * $std-space;
$padding: $topPadding $sidePadding;

.popperWrapper {
    .arrow {
        position: absolute;
        color: white;
        font-size: 40px;
        display: none; } }

// similar to dropdown styling
.popper {
    z-index: 999; // less than modal which is 1000 something
    background-color: white;
    color: $text;
    border: 1px solid $outline;
    @include rounded();
    @include ddshadow();
    padding: 0;
    // overflow-y: auto

    .popperCloseX {
        position: absolute;
        right: $std-space;
        top: $std-space;
        cursor: pointer;
        color: $text;
        font-weight: bold;
        padding: 4px;
        width: 32px;
        height: 32px; }

    .popperCloseX:hover {
        background-color: $outline;
        @include rounded(); }

    .popperHeader {
        padding: 16px;
        background: $lt-blue;
        min-width: 280px;

        h6 {
            font-size: 14px;
            line-height: 16px;
            color: $text;
            margin: 0; } }

    .popperBody {
        padding: 16px;
        display: block;

        .form-group:last-child {
            margin-bottom: 0; }

        .popperBody {
            padding: 0; }

        .optionDetails {
            margin-bottom: 0; } } }
.optionDetailsPopper .popperBody {
    max-height: 350px;
    overflow-y: auto; }
.hasTabs {
    .popper {
        padding: 0;
        background: $off-white;

        .tab {
            padding: $padding;
            background: white; } } }

.containerDetailsPopper {
    .popper {} }

.templateDetailsPopper {
    .popper {
        max-width: 386px; } }

#arrow,
#arrow::before {
    position: absolute;
    width: 12px;
    height: 12px;
    background: inherit;
    top: 10px;
    right: -2px;
    z-index: -1; }

#arrow {
    visibility: hidden;

    &::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg); } }
